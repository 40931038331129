import React, { useState, useEffect } from 'react'
import './Post.css'
import parse from 'html-react-parser'

function Post({ title = '', content = '', image }) {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        }

        handleResize() // Check on initial render

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div className={`post ${image ? 'post-with-image' : 'post-no-image'}`}>
            {isMobile ? (
                <>
                    <h2 className='post-heading'>{parse(title)}</h2>
                    {image && <div className="post-image"><img src={'/images/' + image} alt="Post" /></div>}
                    <div className='post-text'>{parse(content)}</div>
                </>
            ) : (
                <>
                    <div className="post-content">
                        <h2 className='post-heading'>{parse(title)}</h2>
                        <div className='post-text'>{parse(content)}</div>
                    </div>
                    {image && <div className="post-image"><img src={'/images/' + image} alt="Post" /></div>}
                </>
            )}
        </div>
    )
}

export default Post