import React from 'react'
import testimonials from '../../assets/testimonials.json'
import './Testimonial.css'
function Testimonial() {
    return (
        <div className='testimonial'>
            <h2>What our <span className='alter-text'>students say</span></h2>
            <div className='testimonial-layout'>

                {
                    testimonials.map((item, index) => (
                        <div key={index} className='item'>
                            <div className='item-content'>" {item.content}"</div>
                            <ul></ul>
                            <div className='item-name'>{item.name}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Testimonial
