import React, {useState} from 'react'
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from './Form'
import Modal from './Modal'
import './BrochureButton.css'


function DownloadBrochure() {
    const notify = () => toast.success("The brochure will be sent to your email address.");
    const [showModal, setShowModal] = useState(false)
    const submitHandler = () => {
        handleCloseModal();
        notify();
    }

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    return (<>
            <Modal isOpen={showModal} onClose={handleCloseModal}>
                <Form submitHandler={submitHandler}>
                    Download Brochure
                </Form>
            </Modal>
            <ToastContainer position="top-center"/>
            <div className='booking-btn'>
                <button onClick={handleOpenModal}>Download Brochure</button>
            </div>
        </>
    )
}

export default DownloadBrochure