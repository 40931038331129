import React from 'react'
// import Placement from '../components/Header/Placement'
import './Header.css'
import TiltleImage from '../components/header/TiltleImage'
import Placement from '../components/header/Placement'
import Logo from '../components/header/Logo'

function Header() {
    return (
        <div className='header'>
            <Logo/>
            <TiltleImage/>
            <Placement/>
        </div>
    )
}

export default Header