import posts from '../assets/Posts.json'
import launchpadData from '../assets/LaunchData.json'
import './Main.css'
import Post from '../components/utility/Post'
import Qualification from '../components/main/Qualification'
import Topics from '../components/main/Topics'
import CourseDetails from '../components/main/CourseDetails'
import Launchpad from '../components/utility/Launchpad'
import PlacementProcess from '../components/main/PlacementProcess'
import Testimonial from '../components/main/Testimonial'
import HiringPartner from '../components/main/HiringPartner'
import BrochureButton from '../components/utility/BrochureButton'
import Placement from '../components/main/Placement'
import ConnectButton from "../components/utility/ConnetButton";
import React from "react";
function Main() {
    const [post1, post2, post3, post4, post5] = posts;
    const { firstOne, secondOne, faq } = launchpadData;
    return (
        <div className='main'>
            <Post title={post1.title} content={post1.content}></Post>
            <Post title={post2.title} image={post2.image} content={post2.content}></Post>
            <Qualification/>
            <div className='consultation-btn'><ConnectButton> Speak with career experts </ConnectButton></div>
            <Topics/>
            <Post title={post3.title} content={post3.content}></Post>
            <CourseDetails/>
            <Post title={post4.title} image={post4.image} content={post4.content}></Post>
            <Launchpad launchdatas={firstOne}/>
            <Post title={post5.title} image={post5.image} content={post5.content}></Post>
            <Launchpad launchdatas={secondOne} isIndex/>
            <div className='consultation-btn'><ConnectButton> Book Placement Consultation </ConnectButton></div>
            <PlacementProcess/>
            <Placement/>
            <Testimonial/>
            <HiringPartner/>
            <div className='faq-section'>
                <h2>FAQ’s for <span className='alter-text'>SAP FICO</span></h2>
                <Launchpad launchdatas={faq} isIndex isLine/>
            </div>
            <BrochureButton/>
        </div>
    )
}

export default Main