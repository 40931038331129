import React from 'react'
import ConnectButton from '../utility/ConnetButton'
import './ButtonsContainer.css'

function ButtonsContainer() {
    return (
        <div>
            <div className='demo-apply'>
                <div className='demo-btn'><ConnectButton>get free demo</ConnectButton></div>
                <div className='apply-btn'><ConnectButton >Talk to our experts</ConnectButton></div>
            </div>
        </div>
    )
}

export default ButtonsContainer
