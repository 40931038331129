import React from 'react';
import './HiringPartner.css'
import partners from '../../assets/partners.json'
function HiringPartner() {
  return (
    <div className='partner'>
      <h2>Our Hiring <span className='alter-text'>Partners</span></h2>
      <div className='partner-container'>
        {partners.map((imageName, index) => (
          <div className='partner-item' key={index}>
            <img src={`/icons/${imageName}`} alt={`Partner ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default HiringPartner;
