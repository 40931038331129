import React from 'react'
import './Launchpad.css'

function Launchpad({ launchdatas, isIndex ,isLine}) {
    return (
        <div className='launchpad'>
            <div className='launchpad-layout'>
                {
                    launchdatas.map((item, index) => (
                        <div key={index} className='item'>
                            {isIndex && <div className='item-index'>({index + 1})</div>}
                            <div className='item-title'>{item.title}</div>
                            <div className={isLine?'item-content underline':'item-content'}>{item.content}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Launchpad
