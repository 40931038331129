import React from 'react'
import CourseDeatils from '../../assets/CourseDeatils.json'
import './CourseDetails.css'
function CourseDetails() {
    return (
        <div className='course-details'>
            <div className='details-list'>
                {
                    CourseDeatils.details.map((item, index) => (
                        <div key={index} className='item'>
                            <div className='item-number'>({String(index + 1).padStart(2, '0')})</div>
                            <div className='item-text'>{item}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default CourseDetails
