import React from 'react'
import './Qualification.css'
import CourseDetils from '../../assets/CourseDeatils.json'
function Qualification() {
    return (
        <div className='qualification'>
            <h3 className='heading'>Who are all can learn this course?</h3>
            <div className='qualification-grid'>
                {CourseDetils.qualification.map((item, index) => {
                    return (
                        <div key={index} className='item'>
                            <h3 className='item-count'>({String(index + 1).padStart(2, '0')})</h3>
                            <h4>{item}</h4>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Qualification
