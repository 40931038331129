import "./App.css";
import Layout from "../Layouts/Layout";
import Logo from "./header/Logo";
import Header from "../Layouts/Header";
import Footer from "../Layouts/Footer";
import Main from "../Layouts/Main";
import StickyButtons from "./utility/StickyButtons";
import Modal from "./utility/Modal";
import Form from "./utility/Form";
import { useEffect, useState } from "react";

function App() {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 2000);
  });

  return (
    <div className="App">
      <Layout>
        <Header />
        <Main />
        <Footer />
        <StickyButtons />
      </Layout>
      <Modal isOpen={showModal} onClose={handleCloseModal}>
        <Form submitHandler={handleCloseModal}></Form>
      </Modal>
    </div>
  );
}

export default App;
