import React from 'react'
import courseDetails from '../../assets/CourseDeatils.json'
import './PlacementProcess.css'

function PlacementProcess() {
  return (
    <div className='placement-process'>
      <h2>Our Placement <span className='alter-text'>Process</span></h2>
      <div className='placement-layout'>
        {courseDetails.process.map((item, index) => (
          <div key={index} className='item'>
            <img className='item-image' src="/icons/check-mark.png" alt="Check Mark Icon" />
            <div className='item-content'>{item}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PlacementProcess
