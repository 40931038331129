import React from 'react'
import './Topics.css'
import CourseDeatils from '../../assets/CourseDeatils.json'

function Topics() {
    return (
        <div className='topics'>
            <h2>What are all the topics covered in <span className='alter-text'>SAP FICO</span></h2>
            <div className='topics-list'>
                {
                    CourseDeatils.topics.map((item, index) => (
                        <div key={index} className='item'>
                            <span className='item-number'>({String(index + 1).padStart(2, '0')})</span>{item}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Topics
