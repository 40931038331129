import React from 'react';
import './Placement.css';
import placements from '../../assets/placement.json'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Placement() {
   

    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            }
        ],
    };

    return (
        <div>
            <h2 className="heading placement-heading">Our recent <span className='alter-text'>Placements</span>
            </h2>
            <div className="placement">
                <Slider {...settings}>
                    {placements.map((placement, index) => (
                        <div key={index}>
                            <div className="profile-card" style={{ backgroundColor: placement.bg }}>
                                <div className="profile-pic-container">
                                    <div className="profile-pic">
                                        <img src={'/profile/' + placement.image} alt="Profile Pic" />
                                    </div>
                                </div>
                                <div className="client-info">
                                    <h3 className="client-name">{placement.name}</h3>
                                    <p className="client-role">{placement.company}</p>
                                </div>
                                <div className="profile-text">
                                    <div>
                                        <p className="placement-company">{placement.company}</p>
                                        <p className="placement-role">{placement.role}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default Placement;