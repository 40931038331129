import React from 'react'
import Address from '../components/footer/Address'

function Footer() {
    return (
        <div className='footer'>
            <Address />
        </div>
    )
}

export default Footer