import React from 'react'

function Stats({ count, children }) {
    return (
        <div className='stats'>
            <div className='stats-item'>
                <div>
                    <div className='number'>
                        {count && count}
                    </div>
                    <div className='caption'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stats