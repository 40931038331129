import React from 'react'
import './Placement.css'
import Stats from '../utility/Stats'
import ButtonsContainer from './ButtonsContainer'

function Placement() {
  return (
    <div className='placement-section'>
      <div className='stats-container'>
        <Stats count="100%">Job Support</Stats>
        <Stats count="2 Months">Course Duration</Stats>
        <Stats>&nbsp;<h3>Online&<br/>Classroom<br/>training</h3></Stats>
        <Stats count="300+">Students Completed</Stats>
      </div>
      <div className='apply-section'>
        <ButtonsContainer/>
      </div>
    </div>
  )
}

export default Placement