import React from 'react'
import './TitleImage.css'

function TiltleImage() {
  return (
    <div className="title-image">
      <div className="title">
        <h4>Join the World’s Leading Technology SAP – FICO</h4>
          <h1>
              The Best SAP FICO
              <br className="mobile-view"/> Training in Bangalore <br/>
              Awaits You
          </h1>
      </div>
        <div className="image">
        <img src='/images/title-image.png' alt="Title " />
      </div>
    </div>
  )
}

export default TiltleImage